import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from 'react-select';
import { useNavigate } from 'react-router-dom';
import { useUser } from '@clerk/clerk-react';

const UploadPage = () => {
  const [selectedModel, setSelectedModel] = useState({ value: 'base', label: 'Base' });
  const [audioFile, setAudioFile] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [transcriptions, setTranscriptions] = useState([]);
  const navigate = useNavigate();
  const { user } = useUser();

  const modelOptions = [
    { value: 'tiny', label: 'Tiny' },
    { value: 'base', label: 'Base' },
    { value: 'medium', label: 'Medium' },
  ];

  useEffect(() => {
    const fetchTranscriptions = async () => {
      try {
        const response = await axios.get(`https://app.transcribe.digital/transcriptions?userId=${user.id}`);
        setTranscriptions(response.data);
      } catch (err) {
        setError('Error fetching transcriptions.');
      }
    };
    fetchTranscriptions();
  }, [user.id]);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setAudioFile(file);
  };

  const handleUpload = async () => {
    if (!audioFile) {
      setError('Please select an audio file.');
      return;
    }
    setLoading(true);
    setError(null);

    const formData = new FormData();
    formData.append('audio_file', audioFile);
    formData.append('model', selectedModel.value);
    formData.append('userId', user.id);

    try {
      await axios.post('https://app.transcribe.digital/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });

      // Fetch the latest transcriptions after successful upload
      const response = await axios.get(`https://app.transcribe.digital/transcriptions?userId=${user.id}`);
      setTranscriptions(response.data);
    } catch (err) {
      setError('Error uploading file.');
    } finally {
      setLoading(false);
    }
  };

  const handleTranscriptionClick = (transcriptionUrl, audioUrl, fileName) => {
    navigate('/transcriber', { state: { transcriptionUrl, audioUrl, fileName } });
  };

  return (
    <div className="container">
      <h1>Upload Audio File for Transcription</h1>
      <div className="mb-3">
        <label htmlFor="modelSelect" className="form-label">Select Model</label>
        <Select
          id="modelSelect"
          options={modelOptions}
          value={selectedModel}
          onChange={setSelectedModel}
        />
      </div>
      <div className="mb-3">
        <label htmlFor="audioFile" className="form-label">Upload Audio File</label>
        <input type="file" className="form-control" id="audioFile" accept="audio/*" onChange={handleFileChange} />
      </div>
      <button type="button" className="btn btn-primary" onClick={handleUpload} disabled={loading}>
        {loading ? 'Uploading...' : 'Upload and Transcribe'}
      </button>
      {error && <div className="alert alert-danger mt-3">{error}</div>}
      <h2 className="mt-5">Your Transcriptions</h2>
      <ul className="list-group">
        {transcriptions.map((transcription, index) => (
          <li key={index} className="list-group-item" onClick={() => handleTranscriptionClick(transcription.transcriptionUrl, transcription.audioUrl, transcription.fileName)}>
            {transcription.fileName}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default UploadPage;
