  import React from 'react';
  import { BrowserRouter, Routes, Route } from 'react-router-dom';
  import { SignedIn, SignedOut, RedirectToSignIn } from '@clerk/clerk-react';
  import HomePage from './components/HomePage';
  import Transcriber from './components/Transcriber';
  import SignInPage from './auth/SignInPage';
  import SignUpPage from './auth/SignUpPage';
  import UploadPage from './components/UploadPage';

  function App() {
    return (
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/upload" element={<SignedIn><UploadPage /></SignedIn>} />
          <Route path="/transcriber" element={<SignedIn><Transcriber /></SignedIn>} />
          <Route path="/sign-in" element={<SignInPage />} />
          <Route path="/sign-up" element={<SignUpPage />} />
          <Route path="*" element={<SignedOut><RedirectToSignIn /></SignedOut>} />
        </Routes>
      </BrowserRouter>
    );
  }

  export default App;
