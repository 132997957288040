import React from 'react';
import { SignedIn, SignedOut, UserButton } from '@clerk/clerk-react';
import { Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

const HomePage = () => (
  <div style={{ backgroundColor: '#fff', minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
    <nav className="navbar navbar-expand-lg navbar-light bg-light">
      <div className="container">
        <Link className="navbar-brand" to="/">Transcribe.digital</Link>
        <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarNav">
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <Link className="nav-link" to="/">Home</Link>
            </li>
            <SignedOut>
              <li className="nav-item">
                <Link className="nav-link" to="/sign-up">Sign Up</Link>
              </li>
              <li className="nav-item">
                <Link className="nav-link" to="/sign-in">Log In</Link>
              </li>
            </SignedOut>
            <SignedIn>
              <li className="nav-item">
                <UserButton />
              </li>
            </SignedIn>
          </ul>
        </div>
      </div>
    </nav>
    <div className="container text-center mt-5" style={{ flex: 1 }}>
      <h1>Unlimited audio & video transcription</h1>
      <p>Convert audio and video to accurate text in seconds.</p>
      <SignedOut>
        <div style={{ marginTop: '30px' }}>
          <Link to="/sign-up" className="btn btn-primary m-2">Sign Up</Link>
          <Link to="/sign-in" className="btn btn-secondary m-2">Log In</Link>
        </div>
      </SignedOut>
      <SignedIn>
        <div className="mt-4">
          <Link to="/upload" className="btn btn-success m-2">Start Transcribing</Link>
        </div>
      </SignedIn>
      <div className="mt-4">
        <video width="400" autoPlay muted controls>
          <source src="/videos/promo.mp4" type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      </div>
    </div>
    <footer className="text-center py-3">
      <div className="container">
        <p className="mb-0">© 2024 Transcribe.digital. All rights reserved.</p>
      </div>
    </footer>
  </div>
);

export default HomePage;
