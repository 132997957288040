import React from 'react';
import { SignIn } from '@clerk/clerk-react';

const SignInPage = () => (
  <div style={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#f8f9fa'
  }}>
    <SignIn />
  </div>
);

export default SignInPage;
