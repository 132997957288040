import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import axios from 'axios';
import { Modal, Button, Form } from 'react-bootstrap';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, TextRun } from 'docx';
import { jsPDF } from 'jspdf';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileAlt, faFileWord, faFilePdf, faEdit, faPencilAlt } from '@fortawesome/free-solid-svg-icons';
import { UserButton } from "@clerk/clerk-react";
import Select from 'react-select';
import './Transcriber.css';

const Transcriber = () => {
  const [response, setResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [fileName, setFileName] = useState('');
  const [showDownloadModal, setShowDownloadModal] = useState(false);
  const [downloadFormat, setDownloadFormat] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [editIndex, setEditIndex] = useState(null);
  const [editText, setEditText] = useState('');
  const [editSpeaker, setEditSpeaker] = useState('');
  const [showChangeSpeakerModal, setShowChangeSpeakerModal] = useState(false);
  const [newSpeakerName, setNewSpeakerName] = useState('');
  const [selectedSpeaker, setSelectedSpeaker] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [audioFileUrl, setAudioFileUrl] = useState('');
  const audioRef = useRef(null);
  const location = useLocation();

  useEffect(() => {
    const fetchTranscription = async () => {
      setLoading(true);
      try {
        const { transcriptionUrl, audioUrl, fileName } = location.state;

        // Fetch the transcription data
        const res = await axios.get(transcriptionUrl);
        setResponse(res.data.segments);
        setFileName(fileName);
        setAudioFileUrl(audioUrl); // Set the audio URL
      } catch (err) {
        console.error('Error fetching transcription:', err);
        setError('Error fetching transcription.');
      } finally {
        setLoading(false);
      }
    };

    fetchTranscription();
  }, [location.state]);

  const handleDownload = (format) => {
    setDownloadFormat(format);
    setShowDownloadModal(true);
  };

  const downloadFile = () => {
    const date = new Date().toLocaleDateString();
    const text = `${fileName}\n${date}\n\n` + response.map(segment =>
      `${segment.speaker}: ${segment.text}`
    ).join('\n\n');

    if (downloadFormat === 'txt') {
      const blob = new Blob([text], { type: 'text/plain' });
      saveAs(blob, `${fileName}.txt`);
    } else if (downloadFormat === 'docx') {
      const doc = new Document({
        sections: [{
          properties: {},
          children: [
            new Paragraph({ text: fileName, heading: "Title" }),
            new Paragraph({ text: date, heading: "Heading2" }),
            ...text.split('\n\n').map(line => new Paragraph({
              children: [new TextRun({ text: line, break: 1 })],
              spacing: { line: 276 },
            })),
          ],
        }],
      });
      Packer.toBlob(doc).then(blob => {
        saveAs(blob, `${fileName}.docx`);
      });
    } else if (downloadFormat === 'pdf') {
      const doc = new jsPDF();
      const lines = doc.splitTextToSize(text, 180);
      let y = 10;
      for (let i = 0; i < lines.length; i++) {
        doc.text(10, y, lines[i]);
        y += 10;
        if (y > 280) {
          doc.addPage();
          y = 10;
        }
      }
      doc.save(`${fileName}.pdf`);
    }
    setShowDownloadModal(false);
  };

  const handleEdit = (index) => {
    setEditIndex(index);
    setEditText(response[index].text);
    setEditSpeaker(response[index].speaker);
    setShowEditModal(true);
  };

  const handleSaveEdit = () => {
    const updatedResponse = [...response];
    updatedResponse[editIndex].text = editText;
    updatedResponse[editIndex].speaker = editSpeaker;
    setResponse(updatedResponse);
    setEditIndex(null);
    setShowEditModal(false);
  };

  const handleChangeSpeaker = () => {
    const updatedResponse = response.map(segment => {
      if (segment.speaker === selectedSpeaker) {
        return { ...segment, speaker: newSpeakerName };
      }
      return segment;
    });
    setResponse(updatedResponse);
    setShowChangeSpeakerModal(false);
  };

  const handlePlaySegment = (start) => {
    if (audioRef.current) {
      audioRef.current.currentTime = start;
      audioRef.current.play();
    }
  };

  const uniqueSpeakers = Array.from(new Set(response?.map(segment => segment.speaker) || []));

  return (
    <div>
      <div className="header">
        <div className="title">
          <h2>transcribe.digital</h2>
        </div>
        <div className="user-icon">
          <UserButton />
        </div>
      </div>
      <div className={`container ${isEditing ? 'editing' : ''}`}>
        {loading && <div className="text-center mt-3">Loading...</div>}
        {error && <div className="alert alert-danger mt-3">{error}</div>}
        <div className="d-flex">
          <div className="col-md-3 me-4 p-3 border rounded shadow-sm">
            {response && (
              <div className="mt-4">
                <button onClick={() => handleDownload('txt')} className="btn btn-outline-secondary w-100 mb-2">
                  <FontAwesomeIcon icon={faFileAlt} /> Download .txt
                </button>
                <button onClick={() => handleDownload('docx')} className="btn btn-outline-secondary w-100 mb-2">
                  <FontAwesomeIcon icon={faFileWord} /> Download .docx
                </button>
                <button onClick={() => handleDownload('pdf')} className="btn btn-outline-secondary w-100 mb-2">
                  <FontAwesomeIcon icon={faFilePdf} /> Download .pdf
                </button>
                <button className="btn btn-outline-secondary w-100 mb-2" onClick={() => setIsEditing(!isEditing)}>
                  <FontAwesomeIcon icon={faEdit} /> {isEditing ? 'Stop Editing' : 'Start Editing'}
                </button>
                <button className="btn btn-outline-secondary w-100" onClick={() => setShowChangeSpeakerModal(true)}>
                  Change Speaker Name
                </button>
              </div>
            )}
          </div>
          <div className="col-md-8 p-3 border rounded shadow-sm text-left">
            {response && (
              <div className="m-5">
                <h3 className="mb-3 font-weight-bold">
                  {fileName}
                  {isEditing && <FontAwesomeIcon icon={faPencilAlt} className="ms-2" />}
                </h3>
                <h5 className="mb-4 text-muted">{new Date().toLocaleDateString()}</h5>
                {response.map((segment, index) => (
                  <div
                    key={index}
                    className="segment mb-4"
                    onClick={() => handlePlaySegment(parseFloat(segment.start))}
                  >
                    {!isEditing && <div className="play-bubble">Click the text to play</div>}
                    {isEditing && editIndex === index ? (
                      <div>
                        <Button variant="primary" onClick={() => handleEdit(index)} className="mt-2">
                          <FontAwesomeIcon icon={faPencilAlt} /> Edit
                        </Button>
                      </div>
                    ) : (
                      <div>
                        <p className="text-left">
                          <strong>{segment.speaker}:</strong> {segment.text}
                          {isEditing && (
                            <span className="edit-bubble" onClick={(e) => { e.stopPropagation(); handleEdit(index); }}>
                              <FontAwesomeIcon icon={faPencilAlt} /> Edit
                            </span>
                          )}
                        </p>
                        <p className="text-muted text-left">
                          <small>Start: {index === 0 ? '0.00' : parseFloat(segment.start).toFixed(2)}s - End: {parseFloat(segment.end).toFixed(2)}s</small>
                        </p>
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        <div className="audio-footer">
          <audio ref={audioRef} src={audioFileUrl} controls className="w-100"></audio>
        </div>

        <Modal show={showDownloadModal} onHide={() => setShowDownloadModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Download File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>File Name</Form.Label>
              <Form.Control
                type="text"
                value={fileName}
                onChange={(e) => setFileName(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowDownloadModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={downloadFile}>
              Download
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showChangeSpeakerModal} onHide={() => setShowChangeSpeakerModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Change Speaker Name</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Select Speaker</Form.Label>
              <Select
                options={uniqueSpeakers.map(speaker => ({ value: speaker, label: speaker }))}
                onChange={(option) => setSelectedSpeaker(option.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>New Speaker Name</Form.Label>
              <Form.Control
                type="text"
                value={newSpeakerName}
                onChange={(e) => setNewSpeakerName(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowChangeSpeakerModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleChangeSpeaker}>
              Change
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal show={showEditModal} onHide={() => setShowEditModal(false)} size="lg">
          <Modal.Header closeButton>
            <Modal.Title>Edit Segment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form.Group className="mb-3">
              <Form.Label>Speaker</Form.Label>
              <Form.Control
                type="text"
                value={editSpeaker}
                onChange={(e) => setEditSpeaker(e.target.value)}
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Text</Form.Label>
              <Form.Control
                as="textarea"
                rows={10}
                value={editText}
                onChange={(e) => setEditText(e.target.value)}
              />
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowEditModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleSaveEdit}>
              Save
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div>
  );
};

export default Transcriber;
