import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { ClerkProvider } from '@clerk/clerk-react';
import './styles.css'; // Import the custom styles
import 'bootstrap/dist/css/bootstrap.min.css'; // Import Bootstrap

const clerkPublishableKey = 'pk_test_YXNzdXJlZC1nZWxkaW5nLTg3LmNsZXJrLmFjY291bnRzLmRldiQ';

if (!clerkPublishableKey) {
  throw new Error("Missing Clerk publishable key.");
}

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <ClerkProvider publishableKey={clerkPublishableKey}>
      <App />
    </ClerkProvider>
  </React.StrictMode>
);
